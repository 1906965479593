import _extends from "@babel/runtime/helpers/esm/extends";
var createDummyElement = function createDummyElement(text, time, options) {
  var element = document.createElement('div');
  var textNode = document.createTextNode(text);
  var spanTimeElement = document.createElement('span');
  spanTimeElement.style.fontSize = '0.750rem';
  spanTimeElement.style["float"] = 'right';
  spanTimeElement.appendChild(document.createTextNode(time));
  element.appendChild(textNode);
  element.appendChild(spanTimeElement);
  element.style.fontFamily = options.font;
  element.style.fontSize = options.fontSize;
  element.style.fontWeight = options.fontWeight;
  element.style.lineHeight = options.lineHeight;
  element.style.width = options.width;
  element.style.maxWidth = options.maxWidth;
  element.style.height = 'auto';
  element.style.wordBreak = options.wordBreak;
  element.style.padding = options.padding;
  element.style.position = 'absolute';
  var parentElement = document.createElement('div');
  parentElement.style.position = 'absolute';
  parentElement.style.visibility = 'hidden';
  parentElement.style.left = '-999px';
  parentElement.style.top = '-999px';
  parentElement.style.width = options.width;
  parentElement.appendChild(element);
  document.body.appendChild(parentElement);
  return {
    element: element,
    parentElement: parentElement
  };
};
var destroyElement = function destroyElement(element) {
  document.body.removeChild(element);
};
var getDefault = function getDefault(value, defaultValue) {
  return value || defaultValue;
};
var getDefaultConfig = function getDefaultConfig(options) {
  var defaultOptions = _extends({}, options);
  defaultOptions.fontSize = getDefault(options.fontSize, '1rem');
  defaultOptions.fontWeight = getDefault(options.fontWeight, 'normal');
  defaultOptions.lineHeight = getDefault(options.lineHeight, 'normal');
  defaultOptions.width = getDefault(options.width, 'auto');
  defaultOptions.maxWidth = getDefault(options.maxWidth, 'none');
  defaultOptions.wordBreak = getDefault(options.wordBreak, 'normal');
  defaultOptions.padding = getDefault(options.padding, '0');
  return defaultOptions;
};
var cache = {};
export var calculateSize = function calculateSize(text, time) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var cacheKey = JSON.stringify({
    options: options,
    text: text
  });
  if (cache[cacheKey]) {
    return cache[cacheKey];
  }
  var _createDummyElement = createDummyElement(text, time, getDefaultConfig(options)),
    element = _createDummyElement.element,
    parentElement = _createDummyElement.parentElement;
  var size = {
    height: element.offsetHeight,
    width: element.offsetWidth
  };
  destroyElement(parentElement);
  cache[cacheKey] = size;
  return size;
};