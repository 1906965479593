import debounce from 'lodash/debounce';
var isScrollDown = function isScrollDown(dir) {
  return dir === 'forward';
};
var isScrollUp = function isScrollUp(dir) {
  return dir === 'backward';
};
var SCROLL_OFFSET = 60;
var makeHandleScroll = function makeHandleScroll(props, scrollRef) {
  var loading = props.loading,
    onLoadPreviousItems = props.onLoadPreviousItems,
    onLoadNextItems = props.onLoadNextItems,
    hasPreviousItems = props.hasPreviousItems,
    hasNextItems = props.hasNextItems;
  var onLoadLess = debounce(function () {
    return onLoadPreviousItems == null ? void 0 : onLoadPreviousItems();
  }, 100);
  var onLoadMore = debounce(function () {
    return onLoadNextItems == null ? void 0 : onLoadNextItems();
  }, 100);
  return function (_ref, _ref2) {
    var scrollOffset = _ref.scrollOffset,
      scrollDirection = _ref.scrollDirection;
    var height = _ref2.height;
    if (loading) {
      return;
    }
    var checkStartOfScroll = function checkStartOfScroll() {
      if (hasPreviousItems && isScrollUp(scrollDirection) && scrollRef.current) {
        var startOfScroll = scrollOffset < SCROLL_OFFSET;

        if (startOfScroll) {
          onLoadLess();
        }
      }
    };
    var checkEndOfScroll = function checkEndOfScroll() {
      if (hasNextItems && isScrollDown(scrollDirection) && scrollRef.current) {
        var endOfScroll = scrollRef.current.scrollHeight - height - SCROLL_OFFSET < scrollOffset;

        if (endOfScroll) {
          onLoadMore();
        }
      }
    };
    checkStartOfScroll();
    checkEndOfScroll();
  };
};
export default {
  makeHandleScroll: makeHandleScroll
};