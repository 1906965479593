import React, { memo } from 'react';
import { func, number, string } from 'prop-types';
import Card from '@magalu/stereo-ui/atoms/Card';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import NumberedButton from '@magalu/stereo-ui/atoms/NumberedButton';
import { Icon } from '@magalu/stereo-ui-icons';
import { CardStyled, CardTextDate, CardBadgeStyled } from './ChatContactCard.style';
var ChatContactCard = function ChatContactCard(_ref) {
  var name = _ref.name,
    description = _ref.description,
    date = _ref.date,
    onClick = _ref.onClick,
    badgeCount = _ref.badgeCount;
  return React.createElement(CardStyled, {
    onClick: onClick,
    "data-testid": "chat-contact-card"
  }, React.createElement(Flex, {
    flexDirection: "row",
    gap: 3,
    alignItems: "center",
    width: "100%",
    padding: "16px 20px"
  }, React.createElement(CardBadgeStyled, null, React.createElement(NumberedButton, {
    amount: badgeCount,
    backgroundColor: "transparent",
    counterColor: "#0086ff",
    fontColor: "#fff",
    truncateAmountThreshold: 10,
    nullable: false
  }, React.createElement(Icon, {
    name: "SellerStore",
    width: 40,
    height: 40
  }))), React.createElement(Flex, {
    justifyContent: "space-between",
    gap: 2,
    flex: 1
  }, React.createElement("div", null, React.createElement(Card.Subtitle, {
    "data-testid": "chat-contact-card-name",
    fontSize: "1rem",
    lineHeight: "1.125rem",
    fontWeight: 500,
    lines: 1,
    marginTop: 0,
    truncate: true
  }, name), description && React.createElement(Card.Subtitle, {
    color: "scratched",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1rem",
    lines: 1,
    marginTop: 1,
    truncate: true
  }, description)), date && React.createElement(CardTextDate, {
    color: "scratched",
    fontWeight: 400,
    marginTop: 0,
    whiteSpace: "nowrap"
  }, date))));
};
ChatContactCard.defaultProps = {
  badgeCount: 0,
  date: undefined,
  description: undefined
};
process.env.NODE_ENV !== "production" ? ChatContactCard.propTypes = {
  badgeCount: number,
  date: string,
  description: string,
  name: string.isRequired,
  onClick: func.isRequired
} : void 0;
export default memo(ChatContactCard);