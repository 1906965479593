import React from 'react';
import { ProductCard } from '@magalu/stereo-ui/organisms';
import { string } from 'prop-types';
import { ProductMessageRoot } from './ProductMessage.style';
var emptyRating = {
  count: 0,
  score: 0
};
var getHeight = function getHeight() {
  return 119;
};
var ProductMessage = function ProductMessage(_ref) {
  var image = _ref.image,
    price = _ref.price,
    title = _ref.title,
    url = _ref.url;
  return React.createElement(ProductMessageRoot, {
    "data-testid": "chat-message-product"
  }, React.createElement(ProductCard, {
    displayMode: "list",
    installment: {
      amount: price,
      paymentMethodDescription: '',
      quantity: '0',
      totalAmount: price
    },
    title: title,
    image: image,
    rating: emptyRating,
    url: url,
    allowEmptyRating: false,
    hidePrice: true,
    hidePriceMode: "hide"
  }));
};
process.env.NODE_ENV !== "production" ? ProductMessage.propTypes = {
  image: string.isRequired,
  price: string.isRequired,
  title: string.isRequired,
  url: string.isRequired
} : void 0;
ProductMessage.getHeight = getHeight;
export default ProductMessage;