import _extends from "@babel/runtime/helpers/esm/extends";
import React, { memo } from 'react';
import { Box, Flex, Text } from '@magalu/stereo-ui/atoms';
import { Icon } from '@magalu/stereo-ui-icons';
import { IconBadge, IconContainer } from './EmptyState.style';
var EmptyState = function EmptyState(props) {
  return React.createElement(Flex, _extends({}, props, {
    flexDirection: "column",
    justifyContent: "center",
    "data-testid": "EmptyState-Root"
  }), React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(IconContainer, null, React.createElement(Icon, {
    name: "SellerStore",
    height: "104px",
    width: "104px"
  }), React.createElement(IconBadge, {
    title: "N\xFAmero de conversas: 0"
  }, React.createElement(Text, {
    color: "text.white",
    fontWeight: "bold"
  }, "0")))), React.createElement(Box, {
    textAlign: "center",
    p: "2"
  }, React.createElement(Text, {
    fontSize: 5,
    lineHeight: "big",
    fontWeight: "bold",
    mb: 4
  }, "Voc\xEA ainda n\xE3o tem conversas com lojistas")));
};
export default memo(EmptyState);