import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import { getPaletteColor, themeGet } from '@magalu/stereo-ui-styles';
export var DateMessageRoot = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  align-items: center;\n  gap: ", ";\n  padding: 8px 4px 16px 4px;\n\n  > hr {\n    flex: 1;\n    margin: 0;\n    height: 0px;\n    border-width: 1px 0 0 0;\n    border-color: ", ";\n  }\n\n  > span {\n    color: ", ";\n    font-weight: ", ";\n    font-size: ", "px;\n    line-height: ", "px;\n    background-color: ", ";\n    border-radius: ", ";\n    padding: 4px 16px;\n    flex: none;\n    flex-grow: 0;\n  }\n"])), function (props) {
  return themeGet('space.2')(props);
}, function (props) {
  return getPaletteColor('text.white')(props);
}, function (props) {
  return getPaletteColor('text.white')(props);
}, themeGet('fontWeights.medium'), themeGet('fontSizes.2'), themeGet('lineHeights.tall'), function (props) {
  return themeGet('colors.gray')(props);
}, function (props) {
  return themeGet('radii.big')(props);
});