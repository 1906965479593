import React from 'react';
import { string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { ContainerRoot, Avatar } from './MessageChainHeader.styles';
var MessageChainHeader = function MessageChainHeader(props) {
  var label = props.label,
    avatarIcon = props.avatarIcon;
  return React.createElement(ContainerRoot, {
    "data-testid": "chat-conversation-header-root"
  }, React.createElement(Avatar, null, React.createElement(Icon, {
    name: avatarIcon,
    color: "background.primary",
    width: 48,
    height: 48
  })), React.createElement("span", {
    "data-testid": "chat-conversation-header-title"
  }, label));
};
process.env.NODE_ENV !== "production" ? MessageChainHeader.propTypes = {
  label: string.isRequired,
  avatarIcon: string.isRequired
} : void 0;
export default MessageChainHeader;