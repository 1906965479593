var PALETTE_TOKENS = {
  alertDangerBase: 'palette.alert.danger.base',
  gray: 'colors.gray',
  textAttributeLabel: 'palette.text.attributeLabel',
  textWhite: 'palette.text.white'
};
export var CARD_ALIGNMENT = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end'
};
export var CARD_PALETTE = {
  alert: {
    background: PALETTE_TOKENS.alertDangerBase,
    text: PALETTE_TOKENS.textAttributeLabel
  },
  error: {
    background: PALETTE_TOKENS.alertDangerBase,
    text: PALETTE_TOKENS.textAttributeLabel
  },
  gray: {
    background: PALETTE_TOKENS.gray,
    text: PALETTE_TOKENS.textWhite
  },
  primary: {
    background: 'palette.primary.base',
    text: PALETTE_TOKENS.textWhite
  },
  secondary: {
    background: 'palette.background.lighter',
    text: PALETTE_TOKENS.textAttributeLabel
  }
};
export var CORNER_SNAP_STYLE = {
  center: '8px',
  left: '8px 8px 8px 0',
  right: '8px 8px 0 8px'
};