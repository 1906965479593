import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children"];
import React from 'react';
import { node } from 'prop-types';
import { DateMessageRoot } from './DateMessage.style';
var getHeight = function getHeight() {
  return 50;
};
var DateMessage = function DateMessage(_ref) {
  var children = _ref.children,
    rest = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(DateMessageRoot, _extends({
    "data-testid": "chat-date-message"
  }, rest), React.createElement("hr", null), React.createElement("span", null, children), React.createElement("hr", null));
};
process.env.NODE_ENV !== "production" ? DateMessage.propTypes = {
  children: node.isRequired
} : void 0;
DateMessage.getHeight = getHeight;
export default DateMessage;