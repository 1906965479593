import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children", "icon"];
import React from 'react';
import { node, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Box, Flex } from '@magalu/stereo-ui/atoms';
var MessageCaption = function MessageCaption(_ref) {
  var children = _ref.children,
    icon = _ref.icon,
    rest = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Flex, rest, icon && React.createElement(Box, {
    p: "0 4px"
  }, React.createElement(Icon, {
    name: icon.key,
    color: icon.color,
    width: 16,
    height: 16
  })), React.createElement(Box, null, children));
};
MessageCaption.defaultProps = {
  icon: {
    color: 'text.primary'
  }
};
process.env.NODE_ENV !== "production" ? MessageCaption.propTypes = {
  children: node.isRequired,
  icon: shape({
    color: string,
    key: string.isRequired
  })
} : void 0;
export default MessageCaption;