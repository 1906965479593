import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import { useCallback, useRef } from 'react';
export var useListItemSize = function useListItemSize(listRef) {
  var listItemsMetadataCache = useRef({});
  var resizeListItemHeight = function resizeListItemHeight(index, context) {
    var height = context.elementRef.offsetHeight;
    var windowWidth = context.windowWidth,
      id = context.id;
    listItemsMetadataCache.current = _extends({}, listItemsMetadataCache.current, _defineProperty({}, id, {
      height: height,
      id: id,
      windowWidth: windowWidth
    }));
    listRef.current.resetAfterIndex(index);
  };
  var validateCache = function validateCache(index, context) {
    var cacheItem = listItemsMetadataCache.current[context.id];
    var validationOne = context.windowWidth !== cacheItem.windowWidth;
    var validationTwo = context.elementRef.offsetHeight !== cacheItem.height;
    if (validationOne || validationTwo) {
      resizeListItemHeight(index, context);
    }
  };
  var setItemSize = useCallback(function (index, context) {
    if (listRef.current && context) {
      if (listItemsMetadataCache.current[context.id]) {
        validateCache(index, context);
      } else {
        resizeListItemHeight(index, context);
      }
    }
  }, [listRef.current]);
  var getItemSize = function getItemSize(id) {
    if (listItemsMetadataCache.current[id]) {
      return listItemsMetadataCache.current[id].height;
    }
    return 0;
  };
  return [getItemSize, setItemSize];
};