import React, { memo } from 'react';
import { Link } from '@magalu/stereo-ui/atoms';
import { func, string, oneOf } from 'prop-types';
import { noop } from 'lodash';
import { ModerationReadMoreLink } from '../ChatMessage';
var SubMessageAction = function SubMessageAction(_ref) {
  var label = _ref.label,
    type = _ref.type,
    onClick = _ref.onClick;
  if (type === 'readmore') {
    return React.createElement(ModerationReadMoreLink, {
      label: label
    });
  }
  if (type === 'generic') {
    return React.createElement(Link, {
      color: "text.primary",
      onClick: onClick
    }, label);
  }
  return null;
};
SubMessageAction.defaultProps = {
  onClick: noop
};
process.env.NODE_ENV !== "production" ? SubMessageAction.propTypes = {
  label: string.isRequired,
  onClick: func,
  type: oneOf(['generic', 'readmore']).isRequired
} : void 0;
export default memo(SubMessageAction);