import React from 'react';
import { ModerationReadMoreLink } from '../ChatMessage';
export var createComplexContentElement = function createComplexContentElement(message) {
  if (Array.isArray(message == null ? void 0 : message.content)) {
    var rows = message.content.map(function (data, index) {
      return {
        data: data,
        key: index
      };
    });
    return rows.map(function (content) {
      var data = content.data,
        key = content.key;
      if (Object.prototype.hasOwnProperty.call(data, 'kind')) {
        if (data.kind === 'link') {
          return React.createElement(ModerationReadMoreLink, {
            key: "message-".concat(message.id, "-").concat(key),
            label: data.label
          });
        }
        return data.value;
      }
      return data;
    });
  }
  return message == null ? void 0 : message.content;
};