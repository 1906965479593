import React, { useMemo, useRef } from 'react';
import { Icon } from '@magalu/stereo-ui-icons';
import { func, bool, string } from 'prop-types';
import { Text } from '@magalu/stereo-ui/atoms';
import { SubmitButton, TextInput, TextInputArea, TextInputErrorArea, ErrorIcon, Form } from './MessageForm.style';
import { useMessageForm } from './useMessageForm';
var MessageForm = function MessageForm(_ref) {
  var onSubmit = _ref.onSubmit,
    loading = _ref.loading,
    error = _ref.error;
  var inputRef = useRef();
  var _useMessageForm = useMessageForm({
      handleOnSubmit: onSubmit,
      inputRef: inputRef
    }),
    handleChangeEvent = _useMessageForm.handleChangeEvent,
    handleSubmitEvent = _useMessageForm.handleSubmitEvent,
    handleKeyDownEvent = _useMessageForm.handleKeyDownEvent,
    handleKeyPressEvent = _useMessageForm.handleKeyPressEvent,
    isValid = _useMessageForm.isValid,
    value = _useMessageForm.value;
  var submitButtonIcon = useMemo(function () {
    return loading ? 'AnimatedLoading3' : 'Send';
  }, [loading]);
  return React.createElement(Form, {
    onSubmit: handleSubmitEvent,
    "aria-keyshortcuts": "Enter"
  }, React.createElement(TextInputArea, null, React.createElement(TextInput, {
    style: {
      borderBottomLeftRadius: error ? 0 : '6px'
    },
    "aria-required": "true",
    "aria-errormessage": "message-form-error",
    "aria-invalid": isValid === false,
    value: value,
    ref: inputRef,
    onKeyPress: handleKeyPressEvent,
    onKeyDown: handleKeyDownEvent,
    onChange: handleChangeEvent,
    multiline: true,
    rows: 1,
    fullWidth: true,
    autocomplete: "off",
    tabindex: 0,
    spellcheck: "true",
    placeholder: "Digite uma mensagem..."
  }), React.createElement(SubmitButton, {
    style: {
      borderBottomRightRadius: error ? 0 : '6px !important'
    },
    "aria-label": "Enviar mensagem",
    type: "submit",
    tabindex: 0,
    disabled: !isValid,
    outline: true,
    centered: true,
    variant: "contained",
    color: "default",
    size: "large"
  }, React.createElement(Icon, {
    name: submitButtonIcon,
    "data-icon-name": submitButtonIcon,
    width: 32,
    height: 32
  }))), React.createElement(TextInputErrorArea, {
    bg: "alerts.danger-base",
    style: {
      display: error ? 'flex' : 'none'
    }
  }, React.createElement(ErrorIcon, {
    color: "error",
    fontSize: "1",
    fontWeight: "bold",
    "data-testid": "error-icon"
  }, "!"), React.createElement(Text, {
    "aria-label": error,
    color: "error",
    id: "message-form-error",
    fontSize: "1",
    lineHeight: "150%",
    role: "alert",
    ml: "1"
  }, error)));
};
MessageForm.defaultProps = {
  error: '',
  loading: false
};
process.env.NODE_ENV !== "production" ? MessageForm.propTypes = {
  error: string,
  loading: bool,
  onSubmit: func.isRequired
} : void 0;
export { MessageForm };