export var adaptUserToSender = function adaptUserToSender(data) {
  if (data) {
    return {
      fullname: data.name,
      id: data.id,
      username: data.email
    };
  }
  return data;
};
export var createMessageAsError = function createMessageAsError(_ref) {
  var error = _ref.error,
    message = _ref.message,
    handler = _ref.handler;
  var currentDate = new Date();
  var syntheticId = "chat-message-id-".concat(currentDate.getTime());
  return {
    alignment: 'right',
    content: message,
    contentCaption: {
      clickAction: {
        label: 'Clique aqui para reenviar.',
        onClick: handler,
        type: 'generic'
      },
      iconId: 'SecurityTip',
      text: error
    },
    id: syntheticId,
    palette: 'error',
    timestamp: currentDate.toISOString()
  };
};