import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useRef, useState, useLayoutEffect } from 'react';
export var LAST_INDEX = 99999;
export var getScrollToIndex = function getScrollToIndex(messages, lastItemData) {
  var _lastItemData$lastIte;
  var currentLength = messages.length;
  if (currentLength === 0) {
    return -1;
  }
  var diffItems = currentLength - lastItemData.itemCount;
  if (diffItems === 0) {
    return -1;
  }
  var lastItem = messages[currentLength - 1];
  var isScrollingToUp = lastItem.id === ((_lastItemData$lastIte = lastItemData.lastItem) == null ? void 0 : _lastItemData$lastIte.id);
  return isScrollingToUp ? diffItems : LAST_INDEX;
};
export var useInfiniteList = function useInfiniteList(_ref) {
  var messages = _ref.messages;
  var itemCount = messages.length;
  var mount = useRef(false);
  var listRef = useRef(null);
  var lastItemData = useRef({
    itemCount: 0
  });
  var _useState = useState({
      index: 0
    }),
    _useState2 = _slicedToArray(_useState, 2),
    scrollTo = _useState2[0],
    setScrollTo = _useState2[1];
  useLayoutEffect(function () {
    if (listRef.current) {
      var listItemIndexTarget = getScrollToIndex(messages, lastItemData.current);
      if (listItemIndexTarget > 1) {
        listRef.current.resetAfterIndex(0, true);
        setScrollTo({
          index: listItemIndexTarget
        });
      }
    }
    return function () {
      lastItemData.current = {
        itemCount: itemCount,
        lastItem: messages[itemCount - 1]
      };
    };
  }, [messages, listRef, itemCount]);
  useLayoutEffect(function () {
    var _listRef$current;
    listRef == null ? void 0 : (_listRef$current = listRef.current) == null ? void 0 : _listRef$current.scrollToItem(scrollTo.index, 'start');
  }, [listRef, scrollTo]);
  var setInfiniteListRef = function setInfiniteListRef(ref) {
    listRef.current = ref;
    if (mount.current === false && ref) {
      mount.current = true;
      ref.scrollToItem(LAST_INDEX);
    }
    return ref;
  };
  return {
    setInfiniteListRef: setInfiniteListRef
  };
};