import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import { Box } from '@magalu/stereo-ui/atoms';
export var InfiniteListContainer = styled(Box).attrs(function (props) {
  return _extends({
    flex: '1 1 auto',
    height: '100%',
    minHeight: 0,
    overflow: 'hidden',
    position: 'relative',
    width: '100%'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));