export var CUSTOM_FIELD_DEEP_LINK_PREFIX = 'deepLink';
export var CUSTOM_MESSAGES_WITH_DEEP_LINKS = {
  REPURCHASE: {
    btnTitle: 'Comprar',
    deepLinkId: 'deepLinkPDP'
  },
  REVIEW: {
    btnTitle: 'Avaliar Produto',
    deepLinkId: 'deepLinkReview'
  }
};