import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["items", "onClickItem", "onLoadNextItems", "hasNextItems", "loading"];
import React, { memo } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import { InfiniteList, InfiniteListContainer } from '../ChatInfiniteList';
import ChatContactCard from '../ChatContactCard';
import ChatLoading from '../ChatLoading';
import { EmptyStateStyled } from './ConversationList.style';
var ConversationList = function ConversationList(_ref) {
  var items = _ref.items,
    onClickItem = _ref.onClickItem,
    onLoadNextItems = _ref.onLoadNextItems,
    hasNextItems = _ref.hasNextItems,
    loading = _ref.loading,
    restProps = _objectWithoutProperties(_ref, _excluded);
  var itemCount = items.length;
  var getItemSize = function getItemSize() {
    return 69;
  };
  var renderRow = function renderRow(index) {
    var _item$lastMessage;
    var item = items[index];
    return React.createElement(ChatContactCard, {
      name: item.toUser.fullName,
      description: item == null ? void 0 : (_item$lastMessage = item.lastMessage) == null ? void 0 : _item$lastMessage.content,
      date: item.lastInteractionAt,
      onClick: function onClick() {
        return onClickItem(item, index);
      },
      badgeCount: item.unreadFromCount
    });
  };
  return React.createElement(InfiniteListContainer, _extends({
    "data-testid": "chat-conversation-list"
  }, restProps, {
    bg: "background.white"
  }), React.createElement(InfiniteList, {
    itemCount: itemCount,
    itemSize: getItemSize,
    renderRow: renderRow,
    onLoadNextItems: onLoadNextItems,
    hasNextItems: hasNextItems,
    loading: loading
  }), itemCount === 0 && React.createElement(EmptyStateStyled, null), loading && React.createElement(ChatLoading, null));
};
ConversationList.defaultProps = {
  hasNextItems: undefined,
  loading: undefined,
  onLoadNextItems: undefined
};
process.env.NODE_ENV !== "production" ? ConversationList.propTypes = {
  hasNextItems: bool,
  items: arrayOf(shape({})).isRequired,
  loading: bool,
  onClickItem: func.isRequired,
  onLoadNextItems: func
} : void 0;
ConversationList.ssr = false;
export default memo(ConversationList);