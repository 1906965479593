import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useMemo, useState } from 'react';
import { useTextAreaResize, wrapTextareaValueWithNewline } from './textAreaUtils';
export var useMessageForm = function useMessageForm(_ref) {
  var handleOnSubmitProp = _ref.handleOnSubmit,
    inputRef = _ref.inputRef;
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    inputValue = _useState2[0],
    setInputValue = _useState2[1];
  var _useTextAreaResize = useTextAreaResize({
      ref: inputRef
    }),
    resetHeightTextarea = _useTextAreaResize.resetHeightTextarea;
  var isValid = useMemo(function () {
    return inputValue.trim().length > 0 && inputValue.match(/^[^a-zA-Z0-9]+$/) === null;
  }, [inputValue]);
  function handleKeyPressEvent(event) {
    if (event.code === 'Enter' && event.shiftKey || event.code === 'Enter' && event.ctrlKey) {
      event.preventDefault();
      var compiledValue = wrapTextareaValueWithNewline(event);
      setInputValue(compiledValue);
    }
  }
  function handleChangeEvent(event) {
    setInputValue(event.target.value);
  }
  var handleSubmitEvent = function handleSubmitEvent(event) {
    event.preventDefault();
    if (isValid) {
      handleOnSubmitProp({
        message: inputValue.trim(),
        ref: {
          resetForm: function resetForm() {
            setInputValue('');
          }
        }
      });
      inputRef.current.focus();
      resetHeightTextarea();
    }
  };
  function handleKeyDownEvent(event) {
    if (event.code === 'Enter' && !event.shiftKey && event.code === 'Enter' && !event.ctrlKey) {
      handleSubmitEvent(event);
    }
  }
  return {
    handleChangeEvent: handleChangeEvent,
    handleKeyDownEvent: handleKeyDownEvent,
    handleKeyPressEvent: handleKeyPressEvent,
    handleSubmitEvent: handleSubmitEvent,
    isValid: isValid,
    value: inputValue
  };
};