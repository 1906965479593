import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import { Flex, Button, Input, Box } from '@magalu/stereo-ui/atoms';
import { getTextColor, themeGet, getBackgroundColor } from '@magalu/stereo-ui-styles';
var RADII_DEFAULT = themeGet('radii.default');
var TEXTINPUTAREA_MIN_HEIGHT = '64px';
export var ErrorIcon = styled('span')(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  line-height: normal;\n  margin-top: 5px;\n  background: ", ";\n  border-radius: 50%;\n  width: 10px;\n  height: 10px;\n  display: block;\n  text-align: center;\n  font-size: 8px;\n  flex: none;\n  color: ", ";\n"])), function (props) {
  return themeGet('palette.alert.danger.text')(props);
}, function (props) {
  return themeGet('palette.alert.danger.base')(props);
});
export var Form = styled('form')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: ", "px ", "px ", "px\n    ", "px;\n  box-shadow: ", ";\n"])), themeGet('space.2'), themeGet('space.1'), themeGet('space.1'), themeGet('space.1'), themeGet('boxShadows.4'));
export var SubmitButton = styled(Button)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n  padding: 0;\n  min-width: 48px;\n  height: inherit;\n  min-height: ", ";\n  border-radius: 0px ", " ", " 0px;\n  border: ", "!important;\n  border-left: none !important;\n  :disabled {\n    opacity: 0.7;\n    background-color: ", ";\n    color: ", ";\n    min-height: ", ";\n  }\n  &:focus {\n    background-color: ", ";\n    outline: 1px solid ", ";\n    outline-offset: -3px;\n  }\n  svg {\n    fill: ", ";\n  }\n"])), getTextColor('primary'), getBackgroundColor('white'), TEXTINPUTAREA_MIN_HEIGHT, RADII_DEFAULT, RADII_DEFAULT, themeGet('borders.0'), getBackgroundColor('base'), getTextColor('gray'), TEXTINPUTAREA_MIN_HEIGHT, getBackgroundColor('base'), getBackgroundColor('opacityBlue'), function (props) {
  return getTextColor(props.disabled ? 'gray' : 'primary')(props);
});
export var TextInput = styled(Input)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  min-height: ", ";\n  max-height: 20vh;\n  border-radius: ", " 0px 0px ", ";\n  border: ", ";\n  border-right: none;\n  font-size: ", "px;\n  font-style: normal;\n  font-weight: ", ";\n  line-height: ", ";\n  flex-grow: 1;\n  padding: ", "px;\n  &:focus {\n    background-color: ", ";\n    outline: 1px solid ", ";\n    outline-offset: -3px;\n  }\n"])), TEXTINPUTAREA_MIN_HEIGHT, RADII_DEFAULT, RADII_DEFAULT, themeGet('borders.0'), themeGet('fontSizes.3'), themeGet('fontWeights.regular'), themeGet('lineHeights.input'), themeGet('space.2'), getBackgroundColor('base'), getBackgroundColor('opacityBlue'));
export var TextInputArea = styled(Flex)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  justify-items: stretch;\n"])));
export var TextInputErrorArea = styled(Box)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  border-radius: 0px 0px ", " ", ";\n  border: ", ";\n  padding: ", "px;\n  margin: 4px 0 0 0;\n  background-color: ", ";\n  border-color: #f1aeb5;\n  display: flex;\n  gap: ", "px;\n"])), RADII_DEFAULT, RADII_DEFAULT, themeGet('borders.0'), themeGet('space.1'), function (props) {
  return themeGet('palette.alert.danger.base')(props);
}, themeGet('space.1'));