import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["messages", "store", "infiniteListProps", "postMessage"],
  _excluded2 = ["hasNextItems", "loading", "onFetchMore"];
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { shape, oneOfType, oneOf, string, array, func, bool, number } from 'prop-types';
import { noop } from 'lodash';
import MessageChainHeader from '../MessageChainHeader/MessageChainHeader';
import { InfiniteList, InfiniteListContainer } from '../../ChatInfiniteList';
import ChatLoading from '../../ChatLoading';
import DateMessage from '../../ChatMessage/DateMessage';
import { MessageForm } from './MessageForm';
import { Root } from './MessageChain.style';
import { useInfiniteList } from './useInfiniteList';
import RenderRow from './RenderRow';
import { createItemMapWithDateHeader } from './utils';
var MessageChain = function MessageChain(props) {
  var messages = props.messages,
    store = props.store,
    infiniteListProps = props.infiniteListProps,
    postMessage = props.postMessage,
    rest = _objectWithoutProperties(props, _excluded);
  var hasNextItems = infiniteListProps.hasNextItems,
    loading = infiniteListProps.loading,
    onFetchMore = infiniteListProps.onFetchMore,
    restInfiniteListProps = _objectWithoutProperties(infiniteListProps, _excluded2);
  var _useInfiniteList = useInfiniteList({
      messages: messages
    }),
    setInfiniteListRef = _useInfiniteList.setInfiniteListRef,
    onItemsRendered = _useInfiniteList.onItemsRendered;
  var dateMap = useRef(new Map());
  dateMap.current = useMemo(function () {
    return createItemMapWithDateHeader(messages);
  }, [messages.length]);
  var renderRow = useCallback(function (index) {
    var messageProps = messages[index];
    var messageId = messageProps.id;
    if (dateMap.current.has(messageId)) {
      var _dateMap$current$get = dateMap.current.get(messageId),
        dateAsFormatPtBr = _dateMap$current$get.dateAsFormatPtBr;
      return React.createElement(React.Fragment, null, React.createElement(DateMessage, {
        tabIndex: 0
      }, React.createElement("time", {
        dateTime: messageProps.timestamp,
        "aria-label": "Data das mensagens: ".concat(dateAsFormatPtBr)
      }, dateAsFormatPtBr)), React.createElement(RenderRow, messageProps));
    }
    return React.createElement(RenderRow, messageProps);
  }, [messages]);
  var subjectOfConversation = "Chat com ".concat(store.description, " .");
  return React.createElement(Root, _extends({
    flexDirection: "column",
    "data-testid": "chat-message-chain-root"
  }, rest), React.createElement(MessageChainHeader, {
    label: store.description,
    avatarIcon: "SellerStore"
  }), loading && React.createElement(ChatLoading, {
    align: "top"
  }), React.createElement(InfiniteListContainer, {
    "data-testid": "chat-conversation-list",
    bg: "background.base"
  }, React.createElement(InfiniteList, _extends({
    "aria-live": "polite",
    "aria-relevant": "additions text",
    "aria-label": subjectOfConversation,
    innerTagName: "ol",
    listRef: setInfiniteListRef,
    itemCount: messages.length,
    renderRow: renderRow,
    onLoadPreviousItems: onFetchMore,
    hasPreviousItems: hasNextItems,
    loading: loading,
    onItemsRendered: onItemsRendered
  }, restInfiniteListProps))), React.createElement(MessageForm, {
    onSubmit: postMessage.onSubmit,
    loading: postMessage.loading,
    error: postMessage.error
  }));
};
MessageChain.defaultProps = {
  infiniteListProps: {
    hasNextItems: false,
    loading: false,
    overscanCount: 20
  },
  postMessage: {
    error: null,
    loading: false,
    onSubmit: noop
  },
  store: {
    description: ''
  }
};
process.env.NODE_ENV !== "production" ? MessageChain.propTypes = {
  infiniteListProps: shape({
    hasNextItems: bool,
    loading: bool,
    onFetchMore: func.isRequired,
    overscanCount: number
  }),
  messages: shape({
    alignment: oneOf(['left', 'right', 'center']).isRequired,
    content: oneOfType([array, string]).isRequired,
    contentCaption: shape({
      clickAction: {
        label: string.isRequired,
        onClick: func,
        type: oneOf(['generic', 'readmore']).isRequired
      },
      iconId: string,
      text: string.isRequired
    }),
    id: string.isRequired,
    palette: oneOf(['primary', 'secondary', 'alert', 'gray', 'error']).isRequired,
    timestamp: string.isRequired
  }).isRequired,
  postMessage: shape({
    error: string,
    loading: bool,
    onSubmit: func
  }),
  store: shape({
    description: string
  })
} : void 0;
export default memo(MessageChain);