import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { getBackgroundColor } from '@magalu/stereo-ui-styles';
import { FilterInput } from '@magalu/stereo-ui/molecules';
import { Flex } from '@magalu/stereo-ui/atoms';
export var ConversationStyled = styled(Flex).attrs(function (props) {
  return _extends({
    flexDirection: 'column',
    fontFamily: '"MagaluTextos", sans-serif',
    height: '100%',
    width: '100%'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var FilterInputStyled = styled(FilterInput)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border-radius: 12px;\n  margin: 8px 8px 32px 8px;\n  border: none;\n\n  & div {\n    height: 48px;\n  }\n\n  div & :focus {\n    outline: 1px solid ", ";\n  }\n"])), getBackgroundColor('opacityBlue'));