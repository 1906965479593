import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _typeof from "@babel/runtime/helpers/esm/typeof";
var _excluded = ["renderRow", "listRef", "onScroll"];
import React, { memo, useCallback, useRef } from 'react';
import { func, instanceOf, oneOfType, shape } from 'prop-types';
import memoize from 'memoize-one';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import noop from 'lodash/noop';
import { debounce } from 'lodash';
import { useListItemSize } from './useListItemSize';
import VirtualizedListRow from './VirtualizedListRow';
var createItemData = memoize(function (renderRow) {
  return {
    renderRow: renderRow
  };
});
export var assignRefTo = function assignRefTo() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }
  return function (elementRef) {
    refs.forEach(function (useref) {
      if (typeof useref === 'function') {
        useref(elementRef);
      }
      if (_typeof(useref) === 'object') {
        useref.current = elementRef;
      }
    });
  };
};
var VirtualizedList = function VirtualizedList(_ref) {
  var renderRow = _ref.renderRow,
    listRef = _ref.listRef,
    onScroll = _ref.onScroll,
    props = _objectWithoutProperties(_ref, _excluded);
  var privateListRef = useRef();
  var _useListItemSize = useListItemSize(privateListRef),
    _useListItemSize2 = _slicedToArray(_useListItemSize, 2),
    getItemSize = _useListItemSize2[0],
    setItemSize = _useListItemSize2[1];
  var itemData = createItemData(renderRow);
  var handleScroll = function handleScroll(size) {
    return function (scrollProps) {
      onScroll == null ? void 0 : onScroll(scrollProps, size);
    };
  };
  var handleItemSizeDefinition = useCallback(function (index, context) {
    if (!Object.prototype.hasOwnProperty.call(props, 'itemSize')) {
      setItemSize(index, context);
    }
  }, []);
  var getListItemIdFromIndex = function getListItemIdFromIndex(index) {
    var _props$itemKey;
    return "liid:".concat((_props$itemKey = props.itemKey(index)) !== null && _props$itemKey !== void 0 ? _props$itemKey : index);
  };
  var getListItemKeyFromIndex = function getListItemKeyFromIndex(index) {
    var _props$itemKey2;
    return "likey:".concat((_props$itemKey2 = props.itemKey(index)) !== null && _props$itemKey2 !== void 0 ? _props$itemKey2 : index);
  };
  var renderOnResize = debounce(function (size) {
    return React.createElement(List, _extends({
      ref: assignRefTo(listRef, privateListRef),
      itemData: itemData,
      width: size.width,
      height: size.height,
      itemSize: function itemSize(index) {
        var id = getListItemIdFromIndex(index);
        return getItemSize(id, index);
      },
      onScroll: handleScroll(size)
    }, props, {
      itemKey: getListItemKeyFromIndex
    }), function (_ref2) {
      var data = _ref2.data,
        index = _ref2.index,
        style = _ref2.style;
      var key = getListItemIdFromIndex(index);
      return React.createElement("div", {
        style: style,
        key: key
      }, React.createElement(VirtualizedListRow, {
        id: key,
        data: data,
        index: index,
        onSetSize: handleItemSizeDefinition,
        windowWidth: size.width
      }));
    });
  }, 10, {
    leading: true,
    trailing: true
  });
  return React.createElement(AutoSizer, null, renderOnResize);
};
VirtualizedList.defaultProps = {
  itemKey: noop,
  listRef: noop,
  onScroll: noop
};
process.env.NODE_ENV !== "production" ? VirtualizedList.propTypes = {
  itemKey: func,
  listRef: oneOfType([func, shape({
    current: instanceOf(List)
  })]),
  onScroll: func,
  renderRow: func.isRequired
} : void 0;
VirtualizedList.ssr = false;
export default memo(VirtualizedList);