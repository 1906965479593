import { useEffect } from 'react';
export var useTextAreaResize = function useTextAreaResize(_ref) {
  var inputRef = _ref.ref;
  function resizeTextarea() {
    if (inputRef && inputRef.current) {
      var textarea = inputRef.current;

      var minHeight = textarea.clientHeight;

      textarea.style.height = 'inherit';

      var newHeight = Math.max(minHeight, textarea.scrollHeight);

      textarea.style.height = "".concat(newHeight, "px");
    }
  }
  function resetHeightTextarea() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.style.height = 'initial';
    }
  }

  useEffect(resizeTextarea);
  return {
    resetHeightTextarea: resetHeightTextarea,
    resizeTextarea: resizeTextarea
  };
};
export var wrapTextareaValueWithNewline = function wrapTextareaValueWithNewline(event) {
  var str = event.target.value;
  var posicao = event.target.selectionStart;
  return "".concat(str.slice(0, posicao), "\n").concat(str.slice(posicao));
};