import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { getBackgroundColor, themeGet } from '@magalu/stereo-ui-styles';
export var Avatar = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: ", ";\n  width: 48px;\n  height: 48px;\n  color: ", ";\n  text-align: center;\n"])), function (props) {
  return themeGet('radii.circle')(props);
}, function (props) {
  return getBackgroundColor('white')(props);
});
export var ContainerRoot = styled.header(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  height: 64px;\n  background-color: ", ";\n  align-items: center;\n  justify-content: flex-start;\n  padding: ", "px ", "px;\n\n  & span {\n    margin-left: ", "px;\n    font-size: ", "px;\n    font-weight: ", ";\n  }\n"])), function (props) {
  return getBackgroundColor('white')(props);
}, themeGet('space.2'), themeGet('space.3'), themeGet('space.2'), themeGet('fontSizes.3'), themeGet('fontWeights.medium'));