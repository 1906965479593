import React, { memo } from 'react';
import { shape, oneOfType, oneOf, string, array, func } from 'prop-types';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Message, MessageCaption, ProductMessage, CouponMessage, CustomMessage } from '../../ChatMessage';
import SubMessageAction from '../SubMessageAction';
import { CUSTOM_MESSAGES_WITH_DEEP_LINKS, CUSTOM_FIELD_DEEP_LINK_PREFIX } from '../constants';
import { ListItem } from './MessageChain.style';
var RenderRow = function RenderRow(_ref) {
  var id = _ref.id,
    alignment = _ref.alignment,
    timestamp = _ref.timestamp,
    palette = _ref.palette,
    contentCaption = _ref.contentCaption,
    content = _ref.content,
    customFields = _ref.customFields;
  var rendererMessageCaption = function rendererMessageCaption(captionProps) {
    if (captionProps && captionProps.text) {
      return React.createElement(MessageCaption, {
        icon: captionProps.iconId
      }, React.createElement(Text, {
        fontSize: 0
      }, captionProps.text), (captionProps == null ? void 0 : captionProps.clickAction) && React.createElement(SubMessageAction, {
        label: captionProps.clickAction.label,
        type: captionProps.clickAction.type,
        onClick: captionProps.clickAction.onClick
      }));
    }
    return null;
  };
  var getCustomMessageButtomByDeepLinkId = function getCustomMessageButtomByDeepLinkId() {
    var customFieldsObj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var deepLinkIdCustomField = Object.keys(customFieldsObj).find(function (key) {
      return key.startsWith(CUSTOM_FIELD_DEEP_LINK_PREFIX);
    });
    if (deepLinkIdCustomField) {
      var customMessageConfiguration = Object.values(CUSTOM_MESSAGES_WITH_DEEP_LINKS).find(function (message) {
        return message.deepLinkId === deepLinkIdCustomField;
      });
      return customMessageConfiguration || null;
    }
    return null;
  };
  var renderContent = function renderContent() {
    if (customFields && customFields.promoCouponCode) {
      return React.createElement(CouponMessage, {
        alignment: alignment,
        timestamp: timestamp,
        palette: palette,
        renderCaption: rendererMessageCaption(contentCaption),
        couponCode: customFields.promoCouponCode
      }, content);
    }
    var deepLinkCustomMessage = getCustomMessageButtomByDeepLinkId(customFields);
    if (customFields && deepLinkCustomMessage) {
      return React.createElement(CustomMessage, {
        alignment: alignment,
        btnTitle: deepLinkCustomMessage.btnTitle,
        deepLink: customFields[deepLinkCustomMessage == null ? void 0 : deepLinkCustomMessage.deepLinkId],
        timestamp: timestamp,
        palette: palette,
        renderCaption: rendererMessageCaption(contentCaption)
      }, content);
    }
    if (palette === 'product' && customFields) {
      return React.createElement(ProductMessage, {
        image: customFields.productImage,
        title: customFields.productTitle,
        url: customFields.productUrl
      });
    }
    return React.createElement(Message, {
      alignment: alignment,
      timestamp: timestamp,
      palette: palette,
      renderCaption: rendererMessageCaption(contentCaption)
    }, content);
  };
  return React.createElement(ListItem, {
    tabIndex: 0,
    key: id,
    "data-testid": "chat-message-chain-node"
  }, renderContent());
};
RenderRow.defaultProps = {
  contentCaption: null,
  customFields: undefined,
  timestamp: ''
};
process.env.NODE_ENV !== "production" ? RenderRow.propTypes = {
  alignment: oneOf(['left', 'right', 'center']).isRequired,
  content: oneOfType([array, string]).isRequired,
  contentCaption: shape({
    clickAction: {
      label: string.isRequired,
      onClick: func,
      type: oneOf(['generic', 'readmore']).isRequired
    },
    iconId: string,
    text: string.isRequired
  }),
  customFields: shape({}),
  id: string.isRequired,
  palette: oneOf(['primary', 'secondary', 'alert', 'gray', 'error']).isRequired,
  timestamp: string
} : void 0;
export default memo(RenderRow);