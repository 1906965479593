
import { themeGet } from '@magalu/stereo-ui-styles';
import { CARD_PALETTE, CORNER_SNAP_STYLE, CARD_ALIGNMENT } from './variants';
export var getAlignment = function getAlignment(props) {
  return CARD_ALIGNMENT[props.alignment];
};
export var getBackgroundColor = function getBackgroundColor(props) {
  if (props.palette in CARD_PALETTE) {
    return themeGet(CARD_PALETTE[props.palette].background)(props);
  }
  return 'inherit';
};
export var getCornerSnapStyleFromCardPosition = function getCornerSnapStyleFromCardPosition(props) {
  return CORNER_SNAP_STYLE[props.alignment];
};
export var getTextColor = function getTextColor(props) {
  if (props.palette in CARD_PALETTE) {
    return themeGet(CARD_PALETTE[props.palette].text)(props);
  }
  return 'inherit';
};