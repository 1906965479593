import React, { useCallback, useEffect } from 'react';
import { shape, string } from 'prop-types';
import { Flex, PageLoading, Text } from '@magalu/stereo-ui/atoms';
import { useChatConversation } from '@magalu/mixer-graphql';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useChatUserData } from '../../../hooks';
import { MessageChainRoot } from '../ChatMessageChain/MessageChainRoot';
var ChatProductMessages = function ChatProductMessages(_ref) {
  var _structure$route;
  var data = _ref.data,
    structure = _ref.structure,
    staticProps = _ref["static"];
  var _ref2 = (data == null ? void 0 : data.product) || {},
    seller = _ref2.seller;
  var _useChatUserData = useChatUserData({
      structure: structure
    }),
    userData = _useChatUserData.data;
  var _useChatConversation = useChatConversation({
      sellerId: (seller == null ? void 0 : seller.id) || ((_structure$route = structure.route) == null ? void 0 : _structure$route.sellerId),
      staticProps: staticProps,
      userId: userData == null ? void 0 : userData.id
    }),
    conversation = _useChatConversation.conversation,
    loading = _useChatConversation.loading,
    saveConversationAsRead = _useChatConversation.saveConversationAsRead;
  useEffect(function () {
    saveConversationAsRead();
  }, [saveConversationAsRead]);
  var onReceiveMessages = useCallback(function (messages) {
    var unreadSellerMessages = messages == null ? void 0 : messages.filter(function (message) {
      var _message$moderation;
      var hasApproved = ['REJECTED', 'APPROVED'].includes((_message$moderation = message.moderation) == null ? void 0 : _message$moderation.status);
      return !message.fromUser && hasApproved;
    });
    saveConversationAsRead(unreadSellerMessages.length);
  }, [saveConversationAsRead]);
  var getStore = function getStore() {
    var _conversation$toUser, _conversation$toUser2;
    if (seller) {
      return seller;
    }
    return {
      description: (conversation == null ? void 0 : (_conversation$toUser = conversation.toUser) == null ? void 0 : _conversation$toUser.fullName) || '',
      id: conversation == null ? void 0 : (_conversation$toUser2 = conversation.toUser) == null ? void 0 : _conversation$toUser2.refKey
    };
  };
  if ((userData == null ? void 0 : userData.isLogged) === false) {
    return React.createElement(Flex, {
      width: "100%",
      justifyContent: "center",
      alignItems: "center"
    }, React.createElement(Text, null, "Usu\xE1rio n\xE3o autorizado"));
  }
  if (loading || (userData == null ? void 0 : userData.isLogged) === undefined) {
    return React.createElement(PageLoading, null);
  }
  return React.createElement(MessageChainRoot, {
    "static": staticProps,
    structure: structure,
    store: getStore(),
    conversation: conversation,
    product: data == null ? void 0 : data.product,
    user: userData,
    onReceiveMessages: onReceiveMessages
  });
};
ChatProductMessages.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? ChatProductMessages.propTypes = {
  data: shape({
    product: shape({
      title: string
    })
  }),
  "static": shape({}),
  structure: shape({})
} : void 0;
ChatProductMessages.ssr = true;
export default withLayoutProps(ChatProductMessages);