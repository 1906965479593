import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["loading", "onLoadPreviousItems", "onLoadNextItems", "hasPreviousItems", "hasNextItems"];
import React, { memo, useRef } from 'react';
import { bool, func } from 'prop-types';
import VirtualizedList from '../ChatVirtualizedList';
import utils from './InfiniteList.utils';
var InfiniteList = function InfiniteList(props) {
  var loading = props.loading,
    onLoadPreviousItems = props.onLoadPreviousItems,
    onLoadNextItems = props.onLoadNextItems,
    hasPreviousItems = props.hasPreviousItems,
    hasNextItems = props.hasNextItems,
    otherProps = _objectWithoutProperties(props, _excluded);
  var scrollRef = useRef();
  var onScroll = utils.makeHandleScroll(props, scrollRef);
  return React.createElement(VirtualizedList, _extends({}, otherProps, {
    onScroll: onScroll,
    innerRef: scrollRef
  }));
};
InfiniteList.defaultProps = {
  hasNextItems: undefined,
  hasPreviousItems: undefined,
  loading: undefined,
  onLoadNextItems: undefined,
  onLoadPreviousItems: undefined
};
process.env.NODE_ENV !== "production" ? InfiniteList.propTypes = {
  hasNextItems: bool,
  hasPreviousItems: bool,
  loading: bool,
  onLoadNextItems: func,
  onLoadPreviousItems: func
} : void 0;
InfiniteList.ssr = false;
export default memo(InfiniteList);