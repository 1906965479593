import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import { Flex, Box } from '@magalu/stereo-ui/atoms';
import { themeGet } from '@magalu/stereo-ui-styles';
import { getAlignment, getCornerSnapStyleFromCardPosition, getTextColor, getBackgroundColor } from './styleVariantUtils';
export var MessageContent = styled(Box).attrs(function (props) {
  return _extends({
    backgroundColor: getBackgroundColor(props),
    borderRadius: getCornerSnapStyleFromCardPosition(props),
    boxShadow: "4",
    color: getTextColor(props),
    fontSize: 2,
    lineHeight: themeGet('lineHeights.big')(props),
    maxWidth: '80%',
    padding: 2,
    wordWrap: 'break-word'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .chat-message-link {\n    color: ", ";\n    text-decoration: underline;\n  }\n"])), getTextColor);
export var MessageContentRaw = styled.span.attrs(function (props) {
  return _extends({}, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  word-wrap: break-word;\n  white-space: break-spaces;\n  line-height: 2;\n  text-indent: 2ch;\n  text-rendering: optimizeLegibility;\n  font-size: ", "px;\n  text-align: justify;\n"])), themeGet('fontSizes.2'));
export var MessageRoot = styled(Flex).attrs(function (props) {
  return _extends({
    flexDirection: 'column',
    paddingX: 3,
    paddingY: 2
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: ", ";\n"])), function (props) {
  return getAlignment(props);
});
export var MessageTime = styled('time').attrs(function (props) {
  return _extends({}, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  float: right;\n  padding-top: 16px;\n"])), themeGet('fontSizes.0'));
export var MessageTimePlaceholder = styled('sub')({
  flex: 1,
  visibility: 'hidden'
});