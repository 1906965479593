import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["timestamp", "renderCaption", "children", "alignment", "palette"];
import React, { memo, useMemo } from 'react';
import { Box } from '@magalu/stereo-ui/atoms';
import { node, oneOf, string } from 'prop-types';
import MessageText from './MessageText';
import { MessageContent, MessageRoot, MessageTime, MessageTimePlaceholder, MessageContentRaw } from './Message.style';
import { createDateFormat } from './utils';
var Message = function Message(_ref) {
  var timestamp = _ref.timestamp,
    renderCaption = _ref.renderCaption,
    children = _ref.children,
    alignment = _ref.alignment,
    palette = _ref.palette,
    props = _objectWithoutProperties(_ref, _excluded);
  var timestampParts = useMemo(function () {
    return createDateFormat(timestamp);
  }, [timestamp]);
  return React.createElement(MessageRoot, _extends({
    "data-testid": "chat-message-root",
    alignment: alignment
  }, props), React.createElement(MessageContent, {
    palette: palette,
    alignment: alignment,
    "data-testid": "chat-message-content"
  }, React.createElement(MessageContentRaw, null, typeof children === 'string' ? React.createElement(MessageText, {
    text: children
  }) : children), timestampParts && React.createElement(React.Fragment, null, React.createElement(MessageTimePlaceholder, {
    "aria-hidden": "true"
  }, timestampParts.asTime), React.createElement(MessageTime, {
    "data-testid": "chat-message-time",
    "aria-label": "Hora da mensagem: ".concat(timestampParts.asTime),
    title: "Hora da mensagem: ".concat(timestampParts.asTime),
    dateTime: timestampParts.asIsoString
  }, timestampParts.asTime))), renderCaption && React.createElement(Box, {
    py: 2
  }, renderCaption));
};
Message.defaultProps = {
  children: '',
  renderCaption: null,
  timestamp: ''
};
process.env.NODE_ENV !== "production" ? Message.propTypes = {
  alignment: oneOf(['left', 'right', 'center']).isRequired,
  children: node,
  palette: oneOf(['primary', 'secondary', 'error', 'alert', 'gray']).isRequired,
  renderCaption: node,
  timestamp: string
} : void 0;
export default memo(Message);