import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { memo, useState, useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import debounce from 'lodash/debounce';
import { useChatConversations } from '@magalu/mixer-graphql';
import ChatLoading from '../ChatLoading';
import ConnErrorState from '../ChatAlternateState/ConnErrorState';
import { ConversationList } from '../ChatConversationList';
import { ConversationStyled, FilterInputStyled } from './ChatConversation.style';
var ChatConversation = function ChatConversation(_ref) {
  var onClickItem = _ref.onClickItem,
    userData = _ref.userData,
    staticProps = _ref.staticProps;
  var userId = userData.id;
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    search = _useState2[0],
    setSearch = _useState2[1];
  var _useChatConversations = useChatConversations({
      search: search,
      staticProps: staticProps,
      userId: userId
    }),
    items = _useChatConversations.items,
    loading = _useChatConversations.loading,
    error = _useChatConversations.error,
    hasNextPage = _useChatConversations.hasNextPage,
    fetchNextPage = _useChatConversations.fetchNextPage,
    fetchMore = _useChatConversations.fetchMore,
    refetch = _useChatConversations.refetch;
  useEffect(function () {
    fetchMore();
  }, [search]);
  var handleSearch = function handleSearch(event) {
    setSearch(event.target.value);
  };
  var handleClear = function handleClear() {
    setSearch('');
  };
  var onSearch = debounce(handleSearch, 500);
  if (error) return React.createElement(ConnErrorState, {
    onRetry: refetch
  });
  return React.createElement(ConversationStyled, {
    bg: "background.base"
  }, React.createElement(FilterInputStyled, {
    label: "lojista",
    onChange: onSearch,
    onClearInput: handleClear
  }), !items && loading && React.createElement(ChatLoading, {
    align: "center"
  }), items && React.createElement(ConversationList, {
    loading: loading,
    items: items,
    onClickItem: onClickItem,
    hasNextItems: hasNextPage,
    onLoadNextItems: fetchNextPage
  }));
};
process.env.NODE_ENV !== "production" ? ChatConversation.propTypes = {
  onClickItem: func.isRequired,
  staticProps: shape({
    channel: string,
    organization: string
  }).isRequired,
  userData: shape({
    id: string
  }).isRequired
} : void 0;
export default memo(ChatConversation);