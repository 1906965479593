import React, { memo } from 'react';
import { Box, Button, Flex, Image, Text } from '@magalu/stereo-ui/atoms';
import { func } from 'prop-types';
var ConnErrorState = function ConnErrorState(_ref) {
  var onRetry = _ref.onRetry;
  return React.createElement(Flex, {
    flexDirection: "column",
    justifyContent: "space-around",
    "data-testid": "ConnErrorState-Root"
  }, React.createElement(Box, null, React.createElement(Image, {
    title: "Imagem ilustrativa - Conex\xE3o remota com falha",
    alt: "Imagem ilustrativa - Conex\xE3o remota com falha",
    width: "100%",
    height: "50vh",
    src: "https://wx.mlcdn.com.br/site/chat/cable-disconnected-colorful.svg"
  })), React.createElement(Box, {
    textAlign: "center",
    p: "2",
    role: "alert",
    "aria-live": "assertive"
  }, React.createElement(Text, {
    fontSize: 6,
    fontWeight: "bold",
    mb: 4
  }, "Erro inesperado"), React.createElement(Text, {
    fontSize: 2
  }, "Isso pode ocorrer por uma instabilidade ou problema de conex\xE3o tempor\xE1ria.")), React.createElement(Box, {
    textAlign: "center",
    p: "2"
  }, React.createElement(Text, {
    fontSize: 2,
    mb: 3
  }, "Por favor, aguarde alguns instantes e tente novamente."), React.createElement(Button, {
    full: true,
    centered: true,
    onClick: onRetry,
    size: "large",
    uppercase: false
  }, "Tentar novamente")));
};
process.env.NODE_ENV !== "production" ? ConnErrorState.propTypes = {
  onRetry: func.isRequired
} : void 0;
export default memo(ConnErrorState);