import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { memo, useState } from 'react';
import { Link, Text, Box, Dialog } from '@magalu/stereo-ui/atoms';
import { createPortal } from 'react-dom';
import { string } from 'prop-types';
import { List, ListItem, DialogContainer } from './ModerationReadMoreLink.style';
var ModerationReadMoreLink = function ModerationReadMoreLink(_ref) {
  var label = _ref.label;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var handleClick = function handleClick() {
    setOpen(function (prevValue) {
      return !prevValue;
    });
  };
  return React.createElement(React.Fragment, null, React.createElement(Link, {
    color: "text.primary",
    onClick: handleClick
  }, label), createPortal(React.createElement(DialogContainer, null, React.createElement(Dialog, {
    bg: "background.white",
    type: "dialog",
    position: "bottom",
    showClose: true,
    fullScreen: true,
    visible: open,
    onClickOutside: handleClick
  }, React.createElement(Box, {
    p: "2",
    "data-testid": "Dialog-Container"
  }, React.createElement(Text, {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 4
  }, "Regulamento Aprova\xE7\xE3o/Reprova\xE7\xE3o"), React.createElement(Text, {
    textAlign: "center",
    color: "primary",
    fontSize: 3,
    mb: 4,
    mt: 4
  }, "Chat com lojista Magalu"), React.createElement(Text, {
    lineHeight: "big",
    fontSize: 2
  }, "Para garantir a seguran\xE7a e qualidade da informa\xE7\xE3o, todas as mensagens passam por valida\xE7\xE3o."), React.createElement(Text, {
    color: "primary",
    fontSize: 2,
    mb: 3,
    mt: 3
  }, "N\xE3o \xE9 permitido"), React.createElement(List, null, React.createElement(ListItem, null, "Troca de informa\xE7\xF5es de contatos diretos (CPF, n\xFAmero de telefone, e-mail, perfis em redes sociais e/ou endere\xE7o);"), React.createElement(ListItem, null, "Conte\xFAdo ofensivo, preconceituoso e discriminat\xF3rio de qualquer natureza ou que ofendam a imagem do Magalu;"), React.createElement(ListItem, null, "Enviar mensagens com informa\xE7\xF5es de pagamento;")), React.createElement(Text, {
    color: "primary",
    fontSize: 2,
    mb: 3,
    mt: 3
  }, "Recomendamos"), React.createElement(List, null, React.createElement(ListItem, null, "Compartilhamento de produtos apenas do site magazineluiza.com.br;"), React.createElement(ListItem, null, "Caso tenha recebido informa\xE7\xE3o de mensagem bloqueada, analise os crit\xE9rios acima e envie novamente."))))), document.body));
};
process.env.NODE_ENV !== "production" ? ModerationReadMoreLink.propTypes = {
  label: string.isRequired
} : void 0;
export default memo(ModerationReadMoreLink);