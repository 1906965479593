import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["alignment", "children", "couponCode", "palette"];
import React, { memo } from 'react';
import { node, oneOf, string } from 'prop-types';
import { ButtonStyled, MessageCouponContent, MessageCouponCodeContainer, MessageRoot } from './CouponMessage.style';
import Message from './Message';
var CouponMessage = function CouponMessage(_ref) {
  var alignment = _ref.alignment,
    children = _ref.children,
    couponCode = _ref.couponCode,
    palette = _ref.palette,
    props = _objectWithoutProperties(_ref, _excluded);
  var onClick = function onClick() {
    navigator.clipboard.writeText(couponCode);
  };
  return React.createElement(React.Fragment, null, React.createElement(Message, _extends({
    alignment: alignment,
    palette: palette
  }, props), children), React.createElement(MessageRoot, _extends({
    "data-testid": "chat-message-root",
    alignment: alignment
  }, props), React.createElement(MessageCouponContent, {
    palette: palette,
    alignment: alignment
  }, React.createElement(MessageCouponCodeContainer, null, couponCode), React.createElement(ButtonStyled, {
    color: "primary",
    variant: "contained",
    onClick: onClick
  }, "Copiar c\xF3digo"))));
};
CouponMessage.defaultProps = {
  children: '',
  renderCaption: null,
  timestamp: ''
};
process.env.NODE_ENV !== "production" ? CouponMessage.propTypes = {
  alignment: oneOf(['left', 'right', 'center']).isRequired,
  children: node,
  couponCode: string.isRequired,
  palette: oneOf(['primary', 'secondary', 'error', 'alert', 'gray']).isRequired,
  renderCaption: node,
  timestamp: string
} : void 0;
export default memo(CouponMessage);