

export function isValidISODate(str) {
  return !Number.isNaN(Date.parse(str));
}
export var intlDateFormat = new Intl.DateTimeFormat('pt-BR', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric'
});
export function createItemMapWithDateHeader(messages) {
  var resultMap = new Map();
  var lastFormattedDate;
  messages == null ? void 0 : messages.forEach(function (message) {
    if (!isValidISODate(message.timestamp)) return;
    var date = intlDateFormat.format(new Date(message.timestamp));
    if (lastFormattedDate === undefined || lastFormattedDate !== date) {
      resultMap.set(message.id, {
        dateAsFormatPtBr: date
      });
      lastFormattedDate = date;
    }
  });
  return resultMap;
}