import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled, { css } from 'styled-components';
import { themeGet } from '@magalu/stereo-ui-styles';
export var LoadingContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  background-color: #fff;\n  border-radius: 50%;\n  box-shadow: ", ";\n  left: 50%;\n  transform: translateX(-50%);\n  width: 40px;\n  height: 40px;\n  padding: 4px;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), themeGet('boxShadows.1'), function (_ref) {
  var align = _ref.align;
  return align === 'top' && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      top: 8px;\n    "])));
}, function (_ref2) {
  var align = _ref2.align;
  return align === 'bottom' && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      bottom: 8px;\n    "])));
}, function (_ref3) {
  var align = _ref3.align;
  return align === 'center' && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      top: 50%;\n      transform: translate(-50%, -50%);\n    "])));
});