var intlTimeFormat = new Intl.DateTimeFormat('pt-BR', {
  timeStyle: 'short'
});
export var createDateFormat = function createDateFormat(timestamp) {
  try {
    if (timestamp) {
      var dateInstance = new Date(timestamp);
      return {
        asIsoString: timestamp,
        asTime: intlTimeFormat.format(dateInstance)
      };
    }
  } catch (_unused) {
    return null;
  }
  return null;
};