import _extends from "@babel/runtime/helpers/esm/extends";
import { MODERATION_MESSAGE_RESULT } from '../ChatMessage';
import { CUSTOM_MESSAGES_WITH_DEEP_LINKS } from './constants';
import { createComplexContentElement } from './renderUtils';
export var getCardCaptionIconFromModeration = function getCardCaptionIconFromModeration(message) {
  var iconMapping = {
    NOT_MODERATED: {
      color: 'text.primary',
      key: 'Clock'
    },
    REJECTED: {
      color: 'text.error',
      key: 'SecurityTip'
    },
    WAITING_MODERATION: {
      color: 'text.primary',
      key: 'Clock'
    }
  };
  return iconMapping[message.moderation.status];
};
export var getCardPalletColorFromModeration = function getCardPalletColorFromModeration(moderation, senderKind) {
  if (senderKind === 'seller') {
    var mapping = {
      APPROVED: 'secondary',
      NOT_MODERATED: 'secondary',
      REJECTED: 'alert',
      WAITING_MODERATION: 'gray'
    };
    return mapping[moderation.status];
  }
  if (senderKind === 'customer') {
    var _mapping = {
      APPROVED: 'primary',
      NOT_MODERATED: 'primary',
      REJECTED: 'alert',
      WAITING_MODERATION: 'primary'
    };
    return _mapping[moderation.status];
  }
  return 'gray';
};
export var getCardAling = function getCardAling(sellerKind) {
  var mapping = {
    customer: 'right',
    seller: 'left',
    system: 'center'
  };
  return mapping[sellerKind] || 'center';
};
export var getModerationStatusCaption = function getModerationStatusCaption(message) {
  var _MODERATION_MESSAGE_R;
  return (_MODERATION_MESSAGE_R = MODERATION_MESSAGE_RESULT[message.moderation.status]) !== null && _MODERATION_MESSAGE_R !== void 0 ? _MODERATION_MESSAGE_R : null;
};
export var getMessageSender = function getMessageSender(message) {
  return !(message == null ? void 0 : message.fromUser) ? 'seller' : message.fromUser.type;
};
var isProductMessage = function isProductMessage(message) {
  var _message$customFields;
  return !!(message == null ? void 0 : (_message$customFields = message.customFields) == null ? void 0 : _message$customFields.find(function (field) {
    return field.name === 'productId';
  }));
};
var isCouponMessage = function isCouponMessage(message) {
  var _message$customFields2;
  return !!(message == null ? void 0 : (_message$customFields2 = message.customFields) == null ? void 0 : _message$customFields2.find(function (field) {
    return field.name === 'promoCouponCode';
  }));
};
var isCustomMessageWithDeepLink = function isCustomMessageWithDeepLink(message) {
  var _message$customFields3;
  var deepLinksAllowed = Object.values(CUSTOM_MESSAGES_WITH_DEEP_LINKS).map(function (messageAllowed) {
    return messageAllowed.deepLinkId;
  });
  return message == null ? void 0 : (_message$customFields3 = message.customFields) == null ? void 0 : _message$customFields3.some(function (field) {
    return deepLinksAllowed.includes(field.name);
  });
};
var getCustomFields = function getCustomFields(messageData) {
  var _messageData$customFi;
  return messageData == null ? void 0 : (_messageData$customFi = messageData.customFields) == null ? void 0 : _messageData$customFi.reduce(function (acc, current) {
    acc[current.name] = current.value;
    return acc;
  }, {});
};
export var createProductMessage = function createProductMessage(messageData) {
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var structure = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var getProductFields = function getProductFields() {
    var _structure$config;
    return {
      productId: product.id,
      productImage: product.image,
      productTitle: product.title,
      productUrl: "".concat(structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.desktopSiteDomain, "/").concat(product.url)
    };
  };
  return {
    alignment: 'center',
    content: messageData.content,
    customFields: _extends({}, messageData.id ? getCustomFields(messageData) : getProductFields()),
    id: messageData.id || product.id,
    palette: 'product'
  };
};
export var createMessage = function createMessage(messageData) {
  if (!messageData) {
    return messageData;
  }
  if (isProductMessage(messageData)) {
    return createProductMessage(messageData);
  }
  if (isCouponMessage(messageData)) {
    return {
      alignment: 'left',
      content: createComplexContentElement(messageData),
      contentCaption: {
        clickAction: null,
        iconId: null,
        text: null
      },
      customFields: getCustomFields(messageData),
      id: messageData.id,
      palette: 'secondary',
      timestamp: messageData.whenAt
    };
  }
  if (isCustomMessageWithDeepLink(messageData)) {
    return {
      alignment: 'left',
      content: createComplexContentElement(messageData),
      contentCaption: {
        clickAction: null,
        iconId: null,
        text: null
      },
      customFields: getCustomFields(messageData),
      id: messageData.id,
      palette: 'secondary',
      timestamp: messageData.whenAt
    };
  }
  var cardActionClick = null;
  var cardCaptionText = null;
  var cardCaptionIconId = null;
  var cardPaletteColor = null;
  var senderKind = String(getMessageSender(messageData)).toLowerCase();
  switch (senderKind) {
    case 'seller':
      cardPaletteColor = getCardPalletColorFromModeration(messageData.moderation, 'seller');
      cardCaptionText = getModerationStatusCaption(messageData);
      if (cardCaptionText) {
        cardCaptionIconId = getCardCaptionIconFromModeration(messageData);
        cardActionClick = {
          label: 'Saiba mais',
          type: 'readmore'
        };
      }
      break;
    case 'system':
      cardPaletteColor = 'alert';
      break;
    case 'customer':
      cardPaletteColor = getCardPalletColorFromModeration(messageData.moderation, 'customer');
      if (messageData.moderation.status === 'REJECTED') {
        cardCaptionText = MODERATION_MESSAGE_RESULT.REJECTED;
        cardCaptionIconId = getCardCaptionIconFromModeration(messageData);
        cardActionClick = {
          label: 'Saiba mais',
          type: 'readmore'
        };
      }
      break;
    default:
      cardPaletteColor = 'gray';
      break;
  }
  return {
    alignment: getCardAling(senderKind),
    content: createComplexContentElement(messageData),
    contentCaption: cardCaptionText ? {
      clickAction: cardActionClick,
      iconId: cardCaptionIconId,
      text: cardCaptionText
    } : null,
    id: messageData.id,
    palette: cardPaletteColor,
    timestamp: messageData == null ? void 0 : messageData.whenAt
  };
};
export var createMessageList = function createMessageList(chatMessageList) {
  if (Array.isArray(chatMessageList)) {
    return chatMessageList.map(function (item) {
      return createMessage(item);
    });
  }
  return [];
};