import React from 'react';
import { oneOf } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { LoadingContainer } from './ChatLoading.style';
var ChatLoading = function ChatLoading(_ref) {
  var align = _ref.align;
  return React.createElement(LoadingContainer, {
    align: align,
    "data-testid": "chat-loading"
  }, React.createElement(Icon, {
    name: "AnimatedLoading3",
    width: 32,
    height: 32
  }));
};
ChatLoading.defaultProps = {
  align: 'bottom'
};
process.env.NODE_ENV !== "production" ? ChatLoading.propTypes = {
  align: oneOf(['top', 'center', 'bottom'])
} : void 0;
export default ChatLoading;