import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["alignment", "btnTitle", "children", "deepLink", "palette"];
import React, { memo } from 'react';
import { node, oneOf, string } from 'prop-types';
import Link from '@magalu/stereo-ui/atoms/Link';
import { ButtonStyled, MessageCustomContent, MessageRoot } from './CustomMessage.style';
import Message from './Message';
var CustomMessage = function CustomMessage(_ref) {
  var alignment = _ref.alignment,
    btnTitle = _ref.btnTitle,
    children = _ref.children,
    deepLink = _ref.deepLink,
    palette = _ref.palette,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(React.Fragment, null, React.createElement(Message, _extends({
    alignment: alignment,
    palette: palette
  }, props), children), React.createElement(MessageRoot, _extends({
    "data-testid": "chat-message-root",
    alignment: alignment
  }, props), React.createElement(MessageCustomContent, {
    palette: palette,
    alignment: alignment
  }, React.createElement(Link, {
    href: deepLink,
    target: "_blank",
    underline: false
  }, React.createElement(ButtonStyled, {
    color: "primary",
    variant: "contained"
  }, btnTitle)))));
};
CustomMessage.defaultProps = {
  children: '',
  renderCaption: null,
  timestamp: ''
};
process.env.NODE_ENV !== "production" ? CustomMessage.propTypes = {
  alignment: oneOf(['left', 'right', 'center']).isRequired,
  btnTitle: string.isRequired,
  children: node,
  deepLink: string.isRequired,
  palette: oneOf(['primary', 'secondary', 'error', 'alert', 'gray']).isRequired,
  renderCaption: node,
  timestamp: string
} : void 0;
export default memo(CustomMessage);