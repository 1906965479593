import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect } from 'react';
import { shape } from 'prop-types';
import { Flex, PageLoading, Text } from '@magalu/stereo-ui/atoms';
import Router from 'next/router';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useChatUserData } from '../../../hooks';
import { usePublisher } from '@magalu/mixer-publisher';
import ChatConversation from '../ChatConversation';
var ChatInbox = function ChatInbox(_ref) {
  var structure = _ref.structure,
    staticProps = _ref["static"];
  var route = structure.route;
  var _useChatUserData = useChatUserData({
      structure: structure
    }),
    userData = _useChatUserData.data,
    loading = _useChatUserData.loading;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var hasRedirect = !!(route == null ? void 0 : route.sellerId);
  useEffect(function () {
    if (hasRedirect) {
      var url = "/chat-com-lojista/p/".concat(route.sellerId, "/").concat((route == null ? void 0 : route.productId) ? route.productId : '');
      Router.replace(url);
    }
  }, []);
  useEffect(function () {
    if (userData) {
      var _structure$cookies, _structure$cookies2;
      publish('sellerchat:inbox:conversations:onview', {
        userId: userData.id,
        userIsLogged: userData.isLogged,
        userLocation: _extends({}, structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.location, {
          zipCode: structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.zipCode
        })
      });
    }
  }, [userData]);
  var onClickItem = function onClickItem(item) {
    var _item$toUser;
    publish('sellerchat:inbox:conversations:onclick', {
      sellerId: item.toUser.refKey,
      userId: userData.id
    });
    routePush({
      path: "/chat-com-lojista/p/".concat((_item$toUser = item.toUser) == null ? void 0 : _item$toUser.refKey, "/"),
      spa: true
    });
  };
  if (hasRedirect || loading) {
    return React.createElement(PageLoading, null);
  }
  if (!(userData == null ? void 0 : userData.isLogged)) {
    return React.createElement(Flex, {
      width: "100%",
      justifyContent: "center",
      alignItems: "center"
    }, React.createElement(Text, null, "Usu\xE1rio n\xE3o autorizado"));
  }
  return React.createElement(ChatConversation, {
    onClickItem: onClickItem,
    userData: userData,
    staticProps: staticProps
  });
};
ChatInbox.defaultProps = {
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? ChatInbox.propTypes = {
  "static": shape({}),
  structure: shape({})
} : void 0;
ChatInbox.ssr = true;
export default withLayoutProps(ChatInbox);