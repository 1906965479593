import React, { memo } from 'react';
import { string } from 'prop-types';
import uniqueId from 'lodash/uniqueId';
var MessageText = function MessageText(_ref) {
  var text = _ref.text;
  var urls = text.match(/https?:\/\/\S+/g);
  if (!urls) {
    return text;
  }
  return text.split(/(https?:\/\/\S+)/g).map(function (url) {
    if (urls.includes(url)) {
      return React.createElement("a", {
        "data-testid": "chat-message-link",
        className: "chat-message-link",
        key: uniqueId('chat-message-link-'),
        href: url,
        rel: "noopener noreferrer"
      }, url);
    }
    return url;
  });
};
MessageText.defaultProps = {
  text: ''
};
process.env.NODE_ENV !== "production" ? MessageText.propTypes = {
  text: string
} : void 0;
export default memo(MessageText);