import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useState, useCallback, useEffect } from 'react';
import { createMessageList } from '../utils';
export var useChatLocalStore = function useChatLocalStore(initialValue) {
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    localMessages = _useState2[0],
    setLocalMessages = _useState2[1];
  var _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    remoteMessages = _useState4[0],
    setRemoteMessages = _useState4[1];
  useEffect(function () {
    var messagesList = createMessageList(initialValue);
    var newLocalMessages = localMessages.reduce(function (acc, message) {
      var exists = messagesList.find(function (item) {
        return item.id === message.id;
      });
      if (exists) {
        return acc;
      }
      return [].concat(_toConsumableArray(acc), [message]);
    }, []);
    setLocalMessages(newLocalMessages);
    setRemoteMessages(messagesList);
  }, [JSON.stringify(initialValue)]);
  var removeMessage = useCallback(function (id) {
    setLocalMessages(function (prev) {
      return prev.filter(function (item) {
        return item.id !== id;
      });
    });
  }, [setLocalMessages]);
  var addMessage = useCallback(function (newMessage) {
    setLocalMessages(function (prev) {
      return prev.concat(newMessage);
    });
  }, [setLocalMessages]);
  return {
    addMessage: addMessage,
    messages: remoteMessages.concat(localMessages),
    removeMessage: removeMessage
  };
};