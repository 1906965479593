import React, { memo, useEffect, useRef } from 'react';
import { func, number, shape, string } from 'prop-types';
import noop from 'lodash/noop';
var VirtualizedListRow = function VirtualizedListRow(props) {
  var rowRef = useRef();
  var data = props.data,
    index = props.index,
    id = props.id,
    onSetSize = props.onSetSize,
    windowWidth = props.windowWidth;
  var renderRow = data.renderRow;
  useEffect(function () {
    onSetSize(index, {
      elementRef: rowRef.current,
      id: id,
      windowWidth: windowWidth
    });
  }, [onSetSize, windowWidth, index]);
  return React.createElement("div", {
    ref: rowRef
  }, renderRow(index));
};
VirtualizedListRow.defaultProps = {
  onSetSize: noop
};
process.env.NODE_ENV !== "production" ? VirtualizedListRow.propTypes = {
  data: shape({
    renderRow: func.isRequired
  }).isRequired,
  id: string.isRequired,
  index: number.isRequired,
  onSetSize: func,
  windowWidth: number.isRequired
} : void 0;
VirtualizedListRow.ssr = false;
export default memo(VirtualizedListRow);